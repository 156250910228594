<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getHollsNow()">
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="收款单号:" prop="holl_no">
                <el-input size="small" v-model.trim="searchForm.holl_no" clearable placeholder="请填写收款单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="出运发票号:" prop="tran_nos">
                <el-input size="small" v-model.trim="searchForm.tran_nos" clearable placeholder="请填写出运发票号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="销售合同号:" prop="scon_nos">
                <el-input size="small" v-model.trim="searchForm.scon_nos" clearable placeholder="请填写销售合同号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="客户订单号:" prop="scon_cust_nos">
                <el-input size="small" v-model.trim="searchForm.scon_cust_nos" clearable placeholder="请填写客户订单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="付款单位:" prop="cust_id">
                <el-select filterable v-model="searchForm.cust_id" placeholder="请选择付款单位" size="small" clearable>
                  <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_name" :value="item.cust_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态:" prop="status">
                <el-select filterable v-model="searchForm.status" placeholder="请选择单据状态" size="small" clearable>
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="录入人:" prop="stff_id">
                <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="14">
              <el-form-item label="录入时间:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getHollsNow()" class="vg_ml_16">查询 </el-button>
                <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="收款单号" prop="holl_no" />
            <el-table-column label="收款日期" prop="holl_date">
              <template slot-scope="scope">
                <span v-if="scope.row.holl_date">
                  {{ scope.row.holl_date | formatDate1 }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="出运发票号" prop="tran_nos">
              <template slot-scope="scope">
                <span v-if="scope.row.tran_nos">
                  {{ scope.row.tran_nos }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="销售合同号" prop="scon_nos">
              <template slot-scope="scope">
                <span v-if="scope.row.scon_nos">
                  {{ scope.row.scon_nos }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="客户订单号" prop="scon_cust_nos">
              <template slot-scope="scope">
                <span v-if="scope.row.scon_cust_nos">
                  {{ scope.row.scon_cust_nos }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="付款单位" prop="cust_abbr" />
            <el-table-column label="收款公司" prop="cptt_aname" show-overflow-tooltip />
            <el-table-column label="收款总额" prop="holl_total" />
            <el-table-column label="币种" prop="cust_currency" show-overflow-tooltip />
            <el-table-column label="汇率" prop="tran_usdrate" />
            <el-table-column label="银行费用" prop="holl_chartotal" />
            <el-table-column label="领用金额" prop="holl_claitotal" />
            <el-table-column label="发布金额" prop="holl_issu" />
            <el-table-column label="收款银行" prop="cptt_bank_name" />
            <el-table-column label="公司抬头" prop="cptt_bname" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="单据状态" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini"> {{ helper.getStatusName(scope.row.status).name }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { hollAPI } from '@api/modules/holl';
import { stffAPI } from '@/api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import helper from '@assets/js/helper.js';
import pubPagination from '@/components/common/pubPagination';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'HollList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        holl_no: null,
        tran_nos: null,
        scon_nos: null,
        scon_cust_nos: null,
        cust_id: null,
        stff_id: null,
        status: null,
        timeValue: []
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ],
      custOptionList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/holl_edit') {
        this.initData();
      }
    }
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getHollsList();
      this.getStffUser();
      this.initCust();
    },
    getHollsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(hollAPI.getHolls, {
        holl_no: this.searchForm.holl_no,
        tran_nos: this.searchForm.tran_nos,
        scon_nos: this.searchForm.scon_nos,
        scon_cust_nos: this.searchForm.scon_cust_nos,
        cust_id: this.searchForm.cust_id,
        page_no: this.currentPage,
        stff_id: this.searchForm.stff_id,
        status: this.searchForm.status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        holl_no: null,
        tran_nos: null,
        scon_nos: null,
        scon_cust_nos: null,
        cust_id: null,
        stff_id: null,
        status: null,
        timeValue: []
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getHollsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 回显收票类型
    formaInvstat(row) {
      if (row.pinv_invstat === 0) {
        return '可开票';
      } else if (row.pinv_invstat === 1) {
        return '已开票';
      }
    },
    formaInvtype(row) {
      if (row.holl_invtype === 1) {
        return '增值税发票';
      } else if (row.holl_invtype === 2) {
        return '普通发票';
      }
    },
    formaType(row) {
      if (row.holl_type === 1) {
        return '开净重';
      } else if (row.holl_type === 2) {
        return '开重量';
      }
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/holl_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.holl_id
          })
        )
      });
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getHollsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.holl_id);
          });
          post(hollAPI.deleteHollByIds, { holl_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
